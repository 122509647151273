import HeroSection from "../Components/Hero"
import NavigationBar from "../Components/navbar"
import Background from '../Assets/Images/caravan.jpg'
import Process from "../Components/Process"
import Reviews from "../Components/testimonals"
import WhoAreWe from "../Components/AboutUS"

const HomeScreen = () => {
    return(
        <>
            <div className="px-4 h-full md:px-14 lg:20">
                <NavigationBar/>
                <HeroSection/>
                <Process/>
                <WhoAreWe/>
                <Reviews/>
            </div>
        </>
    )
}

export default HomeScreen