import React from 'react'
const HeroSection = () => {
    return (
        <>
           <div className="w-full">
                <div className="bg-hero bg-cover bg-center mt-8 h-full relative rounded-lg container mx-auto flex flex-col items-center pt-12 sm:pt-24 pb-24 sm:pb-32 md:pb-48 lg:pb-48 xl:pb-48">
                    <div className="w-11/12 sm:w-2/3 mb-5 sm:mb-10 center md:py-28 lg:py-28">
                        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold leading-tight pb-8">Kyle's Leisure Soloutions</h1>
                        <h1 className="text-md sm:text-xl md:text-xl lg:text-2xl xl:text-2xl text-center text-white font-bold leading-tight">The leading Caravan & Motorhome Soloutions throughout Northern Ireland.</h1>
                        <a href='tel:07596321517'><div className='text-white text-center bg-[#FFC15F]  py-2 w-1/2 m-auto mt-8 rounded-sm cursor-pointer'>Call Us</div></a>                
                        <a href='mailto:kylesleisuresoloutions@gmail.com'> <div className='text-white text-center bg-[#FFC15F]  py-2 w-1/2 m-auto mt-2 rounded-sm cursor-pointer'>Email Us</div>  </a>                   
                   </div>
                </div>
            </div>
        </>
    )
}

export default HeroSection