import Image from '../Assets/Images/aboutus.jpg'

const WhoAreWe = () => {
    return(
        <>
            <div className="h-auto w-4/5  m-auto">
                <div class="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
            <div class="flex flex-col lg:flex-row justify-between gap-8">
                <div class="w-full lg:w-5/12 flex flex-col justify-center">
                    <h1 class="text-3xl lg:text-4xl font-bold leading-9 text-black pb-4 text-center  md:text-left  lg:text-left">Who are we</h1>
                    <p class="font-normal text-base leading-6 text-gray-600 text-center md:text-left lg:text-left">
                            Kyle's Leisure Solutions offers mobile caravan and motorhome services &
                            repairs based in Limivady, Co Londonderry, N Ireland.
                            <br></br>
                            <br></br>
                            If it is suitable for the customer on certain larger repairs we can also
                            conduct workshop based reapirs back at our workshop in Limivady.
                            <br></br>
                            <br></br>
                            If it is suitable for the customer on certain larger repairs we can also
                            conduct workshop based reapirs back at our workshop in Limivady.
                            </p>
                </div>
                <div class="w-full lg:w-8/12">
                    <img class="w-full h-full rounded-lg" src={Image} alt="A group of People" />
                </div>
            </div>
    
            <div class="flex lg:flex-row flex-col justify-between gap-8 pt-12">
                <div class="w-full lg:w-5/12 flex flex-col justify-center">
                    <h1 class="text-3xl lg:text-4xl font-bold leading-9 text-black pb-4 text-center md:text-left lg:text-left">Our Services</h1>
                    <p class="text-base leading-6 text-gray-600 font-semibold "> 
                         Services Currently Offered:
                            
                    </p>
                    <br></br>
                    <ul className="list-none text-gray-600 pl-2">
                            <li>Mobile Service & Repair</li>
                            <li>Habination Check</li>
                            <li>Pre Purchase Inspections</li>
                            <li>Dump Inspections</li>
                            <li>Supplying & Fitting of motor movers</li>
                            <li>Appliance Repairs</li>
                        </ul>
                </div>
               
            </div>
        </div>
            </div>
        </>
    )
}

export default WhoAreWe