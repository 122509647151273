import { DevicePhoneMobileIcon, ReceiptPercentIcon , HomeModernIcon } from '@heroicons/react/24/outline'


const Process = () => {
    return (
        <>


            <div className="h-auto w-4/5 pt-20 m-auto">
                <p className="text-center font-bold text-4xl md:text-5xl  lg:text-5xl pb-20">How it works</p>

                <div className="grid gap-10 pb-20 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="rounded-md border border-gray-200 bg-white p-8 shadow-sm">
                        <div className="my-4 flex flex-col items-center">
                            <div className='flex bg-[#9672FF] rounded-xl w-20 h-20 shadow-lg items-center justify-center mb-6'>
                                <DevicePhoneMobileIcon color='white' className='h-10' />
                            </div>
                            <h3 className="text-2xl font-bold md:text-xl">Give us a call</h3>
                        </div>
                        <p className="text-gray-600 text-center">If you are interested in getting your caravan repaired or serviced give us a call now to speak to a member of our team.</p>
                    </div>
                    <div className="rounded-md border border-gray-200 bg-white p-8 shadow-sm">
                        <div className="my-4 flex flex-col items-center">
                        <div className='flex bg-[#42ACF5] rounded-xl w-20 h-20 shadow-lg items-center justify-center mb-6'>
                                <ReceiptPercentIcon color='white' className='h-10' />
                            </div>
                            <h3 className="text-2xl font-bold md:text-xl">Get a quote</h3>
                        </div>
                        <p className="text-gray-600 text-center">If after speaking to our team you are happy to move forward with the repair/service let us know and we can get you a estimated price.</p>
                    </div>
                    <div className="rounded-md border border-gray-200 bg-white p-8 shadow-sm">
                        <div className="my-4 flex flex-col items-center">
                        <div className='flex bg-[#F2B8EC] rounded-xl w-20 h-20 shadow-lg items-center justify-center mb-6'>
                                <HomeModernIcon color='white' className='h-10' />
                            </div>
                            <h3 className="text-2xl font-bold md:text-xl">Sit back & relax</h3>
                        </div>
                        <p className="text-gray-600 text-center">One you accept the quote we will get a date arranged with yourself and have someone out to start the repair.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Process